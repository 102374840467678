<template>
	<main id="main" class="site-main">
		<usfl :featuredItems="usfls" />
		<shownlse :featuredItems="storiesnlse" />
		<nxtlvlclips :featuredItems="storiesnxtlvl" />
		<womenhistory :featuredItems="storieswomenhistory" />
		<blackhistory :featuredItems="storiesblkhistory" />
		<showclips :featuredItems="storiesclips" />
		<showhl :featuredItems="storieshl" />
		<showdoc :featuredItems="storiesdoc" />
	</main>
</template>
<script>
import { core } from "../../config/pluginInit";
import Showdoc from "./MovieCategoryPage/Showdoc";
import Showclips from "./MovieCategoryPage/Showclips.vue";
import Showhl from "./MovieCategoryPage/Showhl.vue";
import Shownlse from "./MovieCategoryPage/ShowNLSE.vue";
import Nxtlvlclips from "./MovieCategoryPage/Nxtlvlclips.vue";
import Blackhistory from "./MovieCategoryPage/Blackhistory.vue";
import Womenhistory from "./MovieCategoryPage/Womenhistory.vue";
import Usfl from "./MovieCategoryPage/USFL.vue";
export default {
	name: "Videos",
	components: {
		Showdoc,
		Showclips,
		Showhl,
		Shownlse,
		Nxtlvlclips,
		Blackhistory,
		Womenhistory,
		Usfl,
	},
	mounted() {
		core.index();
	},
	data() {
		return {
			storieshl: [],
			storiesdoc: [],
			storiesclips: [],
			storiesnxtlvl: [],
			storiesblkhistory: [],
			storiesnlse: [],
			storieswomenhistory: [],
			usfls: [],
		};
	},
	async created() {
		// Get Data from the API
		//     {
		//       videosnles(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//       storieshls(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//       storiesclips(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//       storiesdocs(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//       nxtlvlminutes(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//       blackhistorymonths(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//  womenhistorymonths(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		// usfls(orderBy: createdAt_DESC) {
		//         video {
		//           url
		//         }
		//         title
		//         description
		//       }
		//     }
		//   `
		// )
		//   this.storieshl = data.storieshls
		//   this.storiesdoc = data.storiesdocs
		//   this.storiesclips = data.storiesclips
		//   this.storiesnlse = data.videosnles
		// this.storiesblkhistory = data.blackhistorymonths
		// this.storiesnxtlvl = data.nxtlvlminutes
		// this.storieswomenhistory = data.womenhistorymonths
		// this.usfls = data.usfls
		// console.log('USFLS')
		// console.log(this.usfls)
	},
};
</script>
